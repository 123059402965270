import { addPropertyControls, ControlType, Color, RenderTarget } from "framer"
import { useState, useEffect, useRef, useMemo } from "react"
import { useInView } from "framer-motion"
import ReactCanvasConfetti from "react-canvas-confetti"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function Confetti(props) {
    const [fire, setFire] = useState(false)

    const ref = useRef(null)
    const isInView = useInView(ref, {
        once: !props.activator.replay,
        amount: props.activator.amount,
    })

    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    let colors = []
    for (let i = 0; i < props.colors.length; i++) {
        colors.push(Color.toHexString(Color(props.colors[i])))
    }

    useEffect(() => {
        if (props.activator.trigger === "appear" && !isCanvas) {
            setFire({})
        }
    }, [])

    useEffect(() => {
        if (isInView && props.activator.trigger == "layerInView") {
            setFire({})
        }
    }, [isInView])

    return (
        <div
            ref={ref}
            onClick={(event) => {
                if (props.activator.trigger === "press") {
                    setFire({})
                }
            }}
            onMouseEnter={(event) => {
                if (props.activator.trigger === "hover") {
                    setFire({})
                }
            }}
            style={{
                ...props.style,
            }}
        >
            <ReactCanvasConfetti.default
                particleCount={props.particles}
                ticks={props.time * 60}
                scalar={props.scale}
                angle={props.angle}
                spread={props.spread}
                startVelocity={props.velocity}
                decay={props.decay}
                gravity={props.gravity}
                drift={props.drift}
                colors={colors}
                shapes={[
                    ...Array(props.shapes.square).fill("square"),
                    ...Array(props.shapes.circle).fill("circle"),
                    ...Array(props.shapes.star).fill("star"),
                ]}
                origin={{ x: props.origin.x / 100, y: props.origin.y / 100 }}
                resize={true}
                disableForReducedMotion={props.disableForReducedMotion}
                fire={fire}
                style={{
                    position: props.fullscreen ? "fixed" : "static",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                    pointerEvents: "none",
                }}
            />
        </div>
    )
}

addPropertyControls(Confetti, {
    colors: {
        type: ControlType.Array,
        defaultValue: [
            "#26ccff",
            "#a25afd",
            "#ff5e7e",
            "#88ff5a",
            "#fcff42",
            "#ffa62d",
            "#ff36ff",
        ],
        control: {
            type: ControlType.Color,
        },
    },
    shapes: {
        type: ControlType.Object,
        buttonTitle: "Square, Circle, Star",
        controls: {
            square: {
                type: ControlType.Number,
                defaultValue: 1,
                min: 0,
                step: 1,
                displayStepper: true,
            },
            circle: {
                type: ControlType.Number,
                defaultValue: 1,
                min: 0,
                step: 1,
                displayStepper: true,
            },
            star: {
                type: ControlType.Number,
                defaultValue: 0,
                min: 0,
                step: 1,
                displayStepper: true,
                description: "Ratio of square, circle, and star particles.",
            },
        },
    },
    activator: {
        type: ControlType.Object,
        controls: {
            trigger: {
                type: ControlType.Enum,
                defaultValue: "press",
                options: ["appear", "layerInView", "press", "hover"],
                optionTitles: ["Appear", "Layer In View", "Press", "Hover"],
            },
            amount: {
                type: ControlType.Enum,
                defaultValue: "some",
                options: ["some", "all"],
                optionTitles: ["Any", "All"],
                displaySegmentedControl: true,
                hidden(props) {
                    return props.trigger !== "layerInView"
                },
            },
            replay: {
                type: ControlType.Boolean,
                defaultValue: true,
                hidden(props) {
                    return props.trigger !== "layerInView"
                },
            },
        },
    },
    fullscreen: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    particles: {
        type: ControlType.Number,
        defaultValue: 50,
        min: 1,
        step: 1,
    },
    time: {
        type: ControlType.Number,
        defaultValue: 1,
        min: 0.1,
        step: 0.01,
        unit: "s",
    },
    scale: {
        type: ControlType.Number,
        defaultValue: 1,
        min: 0,
        step: 0.01,
    },
    angle: {
        type: ControlType.Number,
        defaultValue: 90,
        min: -360,
        max: 360,
        unit: "°",
    },
    spread: {
        type: ControlType.Number,
        defaultValue: 45,
        min: 0,
        max: 360,
        unit: "°",
    },
    velocity: {
        type: ControlType.Number,
        defaultValue: 45,
        min: 0,
        description: "Start velocity in px.",
    },
    decay: {
        type: ControlType.Number,
        defaultValue: 0.9,
        min: 0,
        max: 1,
        step: 0.01,
    },
    gravity: {
        type: ControlType.Number,
        defaultValue: 1,
        step: 0.01,
    },
    origin: {
        type: ControlType.Object,
        controls: {
            x: {
                type: ControlType.Number,
                defaultValue: 50,
                unit: "%",
            },
            y: {
                type: ControlType.Number,
                defaultValue: 50,
                unit: "%",
            },
        },
    },
    disableForReducedMotion: {
        type: ControlType.Boolean,
        defaultValue: true,
        title: "Disable for Reduced Motion",
        description: "Disable if the user prefers reduced motion.",
    },
})
